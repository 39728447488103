<template>
  <div class="back">
    <div class="title">没有权限，请联系管理员或点击右下角授权</div>
  </div>
</template>

<script>
import { getToken } from "@/utils/cookie";
export default {
  name: "back",
  mounted() {
    if (getToken()) {
      const fullPath = window.sessionStorage.fullPath;
      if (fullPath) {
        window.sessionStorage.removeItem('fullPath');
        this.$router.push(fullPath);
      } else {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  padding: 20px;
}
</style>